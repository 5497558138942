import Web3 from 'web3'
import USDT from './bnbUsdt.json'
import DAPP from './dapp.json'
import { Toast } from 'vant';
//正式
const ADDRESS_DAPP ="0xbD214807b35186C91b67c11086dd0f2CaCBc5273"
// const ADDRESS_DAPP ="0xD20c1090BE89Da260E447732E25a2b8691498422"
const ADDRESS_ARC ="0x68E500BEe8A7233b65c2beA0Ae8EB4AC9D3310B3"
const ADDRESS_USDT ="0x55d398326f99059fF775485246999027B3197955"


let ethereum = window.ethereum
    let i = setInterval(() => {
    if ((typeof window.ethereum) !== 'undefined') {
        ethereum = window.ethereum      
        handleWatch()  
        clearInterval(i)  
    }
    }, 1000);

export async function set(){
    ethereum = window.ethereum
}
export async function handleWatch () {
    if (!window.ethereum) {
        console.log('Metamask is not installed!')
        return false
      }
    window.ethereum.on('accountsChanged',  (accounts) => {
      if(accounts[0]){
        // let web3s = new Web3(ethereum);
        // let msg = web3s.utils.keccak256('授权签名');
        // let ht= await web3s.eth.sign(msg, accounts[0])
        // .then(res=>{return res});  
        // if(ht){
      sessionStorage.setItem('userWallet',accounts[0])  
    //   store.commit("userWallet", accounts[0]);  
    }else{
        sessionStorage.removeItem('userWallet')
      }     
    })
  }
export async function connectTron() {
    const eth_requestAccounts = await ethereum.request({ method: "eth_requestAccounts" });
    // store.commit("userWallet", eth_requestAccounts[0]);
    // return ['0x4A17A0572c96b2Aa186a0224193eBa587444be44']
    return eth_requestAccounts
}
export function getHoder() {
    let run = async () => {
          let web3s = new Web3("https://bscrpc.com");
          const unioContract =await new web3s.eth.Contract(USDT, ADDRESS_ARC); 
            const events = await unioContract.getPastEvents('Transfer', { fromBlock: 0, toBlock: 'latest' }); 
        //   console.log(events,'limit exceeded');
            const holders = new Set(); 
            events.forEach((event) => { 
                holders.add(event.returnValues.to); }); 
            console.log(`Total holders: ${holders.size}`);         
          let result = holders.size;
         return result 
    };
    return run();
  }
  export function getCanBind(address) {
    let run = async () => {
        ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.canBind(address).call();
         return result
    };
    return run();
  }
    // 查询时间
 export function getTime1() {
    let run = async () => {
        ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.time1().call();
         return result
    };
    return run();
  }
  
  export function getTime2() {
    let run = async () => {
        ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.time2().call();
         return result
    };
    return run();
  }
  export function getTime3() {
    let run = async () => {
        ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.time3().call();
         return result
    };
    return run();
  }
  export function getTime4() {
    let run = async () => {
        ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.time4().call();
         return result
    };
    return run();
  }
   // 查询代币是否授权
 export function getMyLp(address) {    
    let run = async () => {
         ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP, {
              from:address
          });
          let result = await unioContract.methods.getUserLp(address).call();
          let amountT=web3s.utils.fromWei(result.amountT +'', 'ether');
          let amountU=web3s.utils.fromWei(result.amountU +'', 'ether');
          let userLp=web3s.utils.fromWei(result.userLp +'', 'ether');
         return {amountT,amountU,userLp} 
    };
    return run();
  }
 // 查询代币是否授权
 export function getProved(address) {
    let run = async () => {
        ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(USDT, ADDRESS_USDT, {
              from:address
          });
          let result = await unioContract.methods.allowance(address,ADDRESS_DAPP).call()/(10**18);
         return result 
    };
    return run();
  }
  export function getProveds(address) {
    let run = async () => {
        ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(USDT, ADDRESS_ARC, {
              from:address
          });
          let result = await unioContract.methods.allowance(address,ADDRESS_DAPP).call()/(10**18);
         return result 
    };
    return run();
  }
  
 // 授权USDT
 export function toApprove(address,num) {
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(USDT, ADDRESS_USDT);  
        let amount = web3s.utils.toWei(num + '','ether');
        let gas = await web3s.eth.getGasPrice();
        console.log(gas);
        let result = await unioContract.methods.approve(ADDRESS_DAPP, amount).send({from:address, gasPrice:gas}).catch(err => {
            console.log(err.message)
         return false
      });
      if(result){
        return result.transactionHash
    }else{
        return false
    }
    };
    return run();
  }
 // 授权ARC
 export function toApproves(address,num) {
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(USDT, ADDRESS_ARC);  
        let amount = web3s.utils.toWei(num + '','ether');
        let gas = await web3s.eth.getGasPrice();
        console.log(gas);
        let result = await unioContract.methods.approve(ADDRESS_DAPP, amount).send({from:address, gasPrice:gas}).catch(err => {
            console.log(err.message)
         return false
      });
      if(result){
        return result.transactionHash
        }else{
            return false
        } 
    };
    return run();
  }
//   获取用户信息
export async function getUser(address) {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
 
    let result = await unioContract.methods.users(address).call(); 
    if(result){
        // console.log(result,'getUser');
        return  result
    }else{
        return 0
    }
  };
  return run();
}
// 绑定邀请人
export  function toBind(address,referrer){
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
        let result = await unioContract.methods.bindReferrer(referrer).send({from:address, gasPrice:gas}).catch(err => {
            console.log(err.message)
            return false
        });
        console.log(result);
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();

}
// 预约订单
export  function toBookOrder(address,num){
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
       let amount = web3s.utils.toWei(num + '', 'ether');
    //    console.log(address,num);
        let result = await unioContract.methods.bookOrder(amount).send({from:address, gasPrice:gas}).catch(err => {
            console.log(err.message)
            return false
        });
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();
}
// 用户完成订单函数
export  function toCompleteOrder(address,orderIndex,type){
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
        let result = await unioContract.methods.completeOrder(orderIndex,type).send({from:address, gasPrice:gas}).catch(err => {
            console.log(err.message)
            return false
        });
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();
}

// 赎回订单
export  function toRedeemOrder(address,orderIndex){
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
        let result = await unioContract.methods.redeemOrder(orderIndex).send({from:address, gasPrice:gas}).catch(err => {
           console.log(err.message)
            return false
        });
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();
}
// 查询USDT等值的ARC
export async function getUtoArc(num) {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
      let amount = web3s.utils.toWei(num + '', 'ether');
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getArcTokenOfU(amount).call(); 
    if(result){
        return  web3s.utils.fromWei(result+'', 'ether');
    }else{
        return 0
    }
  };
  return run();
}
// 获取个人ARC
export async function getMyArc(address) {  
    let run = async () => {
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(USDT, ADDRESS_ARC);  
    let result = await unioContract.methods.balanceOf(address).call(); 
    if(result){
        return  web3s.utils.fromWei(result+'', 'ether');
    }else{
        return 0
    }
  };
  return run();
}
// 获取用户订单及其索引 status 0全部 1预约中 2已完成 3已赎回
export async function getOrderList(address) {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getOrders(address,0).call(); 
    console.log(result);
    if(result){
        return  result
    }else{
        return []
    }
  };
  return run();
}
// 我的社区显示直推信息
export async function getReferralsInfo(address) {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getDirectReferralsInfo(address).call(); 
    console.log(result);
    if(result){
        return  result
    }else{
        return []
    }
  };
  return run();
}
// 契约池金额
export async function getMyDeedPool() {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getDeedPool().call(); 
    if(result){
        return  web3s.utils.fromWei(result+'', 'ether');
    }else{
        return 0
    }
  };
  return run();
}

// 节点池金额
export async function getMyNodePool() {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getNodePool().call(); 
    if(result){
        return  web3s.utils.fromWei(result+'', 'ether');
    }else{
        return 0
    }
  };
  return run();
}

// 排名池金额
export async function getMyRankPool() {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getRankPool().call(); 
    if(result){
        return  web3s.utils.fromWei(result+'', 'ether');
    }else{
        return 0
    }
  };
  return run();
}
// 节点数量
export async function getMyNodeCount() {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getNodeCount().call(); 
    if(result){
        return result
    }else{
        return 0
    }
  };
  return run();
}
// 静态记录
export async function getMyHistoryStatic(address,size) {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getHistoryStatic(address,size).call(); 
    if(result){
        return  result
    }else{
        return []
    }
  };
  return run();
}
// 动态记录
export async function getMyHistoryDynamic(address,size) {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getHistoryDynamic(address,size).call(); 
    if(result){
        return  result
    }else{
        return []
    }
  };
  return run();
}
// 节点记录
export async function getMyHistoryNode(address,size) {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getHistoryNode(address,size).call(); 
    if(result){
        return  result
    }else{
        return []
    }
  };
  return run();
}
// 排名记录
export async function getMyHistoryRank(address,size) {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getHistoryRank(address,size).call(); 
    if(result){
        return  result
    }else{
        return []
    }
  };
  return run();
}
// 排名列表
export async function getMyRankUsers() {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     console.log("getMyRankUsers");
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getRankUsers().call(); 
    if(result){
        return  result
    }else{
        return []
    }
  };
  return run();
}
// 静态提现
export  function toWithdrawalStatic(address){
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
        let result = await unioContract.methods.withdrawalStatic().send({from:address, gasPrice:gas}).catch(err => {
           console.log(err.message)
            return false
        });
        console.log(result);
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();
}

// 动态提现
export  function toWithdrawalDynamic(address){
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
        let result = await unioContract.methods.withdrawalDynamic().send({from:address, gasPrice:gas}).catch(err => {
           console.log(err.message)
            return false
        });
        console.log(result);
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();
}
// 节点提现
export  function toWithdrawalNode(address){
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
        let result = await unioContract.methods.withdrawalNode().send({from:address, gasPrice:gas}).catch(err => {
           console.log(err.message)
            return false
        });
        console.log(result);
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();
}

// 排名提现
export  function toWithdrawalRank(address){
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
        let result = await unioContract.methods.withdrawalRank().send({from:address, gasPrice:gas}).catch(err => {
           console.log(err.message)
            return false
        });
        console.log(result);
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();
}



export  function transfers(address,amount){
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(ABIS, ADDRESS_ETH, {
            from:address
        });
       amount = web3s.utils.toWei(amount + '', 'ether');
        let result = await unioContract.methods.transfer(get_Batpl,amount).send({from:address}).catch(err => {
           console.log(err.message)
        });
        // let result = await unioContract.methods.balanceOf(address).call()/(10 ** 18);
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();

}




//HT转账
export function transferHt(address) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let msg = web3s.utils.keccak256('授权签名');
        let ht= await web3s.eth.sign(msg, address)
        .then(res=>{return res});
    // let amount = web3s.utils.toWei(0+'' , 'ether');
    //     let result=await  web3s.eth.sendTransaction({
    //        from:address,
    //        to:get_HT,
    //        value:amount
    //    }).then(res=>{
    //        return res.transactionHash
    //    }).catch(()=>{ })
       return ht  
  };
  return run();
}


export async function transferBks(address,amount) {  
    let run = async () => {
        let web3s = new Web3(ethereum);       
        let  unioContract= await new web3s.eth.Contract(BK, ADDRESS_BK);
            amount =web3s.utils.toWei(amount+'', 'gwei'); //amount*10**9;  
            
            console.log('-------',amount);               
    let result = await unioContract.methods.transfer(get_Join,amount).send({from:address}).catch(err => {
        console.log(err.message)
    });
    if(result){
        return result.transactionHash
    }else{
        return false
    }
  };
  return run();
}



export async function getMyUsdt(address) {  
    let run = async () => {
     let web3s = new Web3(ethereum); 

     let unioContract= await new web3s.eth.Contract(USDT, ADDRESS_USDT);  
    let result = await unioContract.methods.balanceOf(address).call(); 
    if(result){
        return  web3s.utils.fromWei(result+'', 'ether');
    }else{
        return 0
    }
  };
  return run();
}
export async function transferTypes(type,address,amount){
    if(type==1){
       let result =await transferBk(address,amount).then(res=>{
        return res}).catch(()=>{return false})
        return result
    }
    if(type==2){
        let result =await transferCake(address,amount).then(res=>{
            return res}).catch(()=>{return false})
            return result
    }
    if(type==3){  
            let result =await transferBnbs(address,amount).then(res=>{
                return res}).catch(()=>{return false})
                return result
    }
}











